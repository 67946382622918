import {
  Box,
  Container,
  Divider,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import Iconify from "../components/Iconify";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../hooks/useResponsive";
import { useLocation } from "react-router-dom";
import useData from "../hooks/useData";

const icons = [
  {
    icon: "bi:github",
    link: "https://github.com/AIProCo/AIProCo",
    target: "_blank",
  },
  {
    icon: "bi:youtube",
    link: "https://www.youtube.com/channel/UCLKys_ZR-aq_WC6rcjTkJfQ/featured",
    target: "_blank",
  },
];

export default function Footer() {
  const theme = useTheme();

  const xs_styles = {
    textDecoration: "none",
    fontSize: "0.7rem",
    color: theme.palette.text.secondary,
  };
  const md_styles = {
    textDecoration: "none",
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  };

  const isDesktop = useResponsive("up", "md");

  const { pathname } = useLocation();
  const info = pathname.split("/").at(-1);
  // const { doneHTML } = useData();

  return (
    <Box component="footer">
      <Divider />
      <Container sx={{ pt: 3.5, pb: 3.5 }}>
        <Stack
          spacing={2}
          direction={isDesktop ? "row" : "column"}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            spacing={isDesktop ? 5 : 1}
            direction={isDesktop ? "row" : "column"}
            alignItems="center"
          >
            <Typography
              style={isDesktop ? md_styles : xs_styles}
              component="div"
              sx={{
                color: "text.secondary",
              }}
            >
              © 2023 AIPro
            </Typography>
            <Stack spacing={5} direction="row" alignItems="center">
              <a
                href={info === "en" ? `/privacy-policy/en` : "/privacy-policy"}
                // href={`./${pathname}/privacy-policy`}
                style={isDesktop ? md_styles : xs_styles}
              >
                Privacy Policy
              </a>
              <a
                href={
                  info === "en" ? `/terms-of-services/en` : "/terms-of-services"
                }
                // href={`./${pathname}/terms-of-services`}
                variant="caption"
                component="div"
                style={isDesktop ? md_styles : xs_styles}
              >
                Terms of Services
              </a>
            </Stack>
          </Stack>

          {isDesktop && (
            <Stack spacing={8} direction="row">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Iconify
                  icon="mdi:email"
                  width={22}
                  sx={{
                    mr: 1,
                  }}
                  color="text.secondary"
                />
                <a href="mailto:cspk@skku.edu" style={md_styles}>
                  <Typography
                    fontSize={14}
                    component="div"
                    style={isDesktop ? md_styles : xs_styles}
                  >
                    cspk.aipro@gmail.com
                  </Typography>
                </a>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Iconify
                  icon="material-symbols:call"
                  width={22}
                  sx={{
                    mr: 1,
                  }}
                  color="text.secondary"
                />
                <a href="tel:02-760-0189" style={md_styles}>
                  <Typography
                    fontSize={14}
                    component="div"
                    style={isDesktop ? md_styles : xs_styles}
                  >
                    02-760-0189
                  </Typography>
                </a>
              </Box>
            </Stack>
          )}

          <Stack
            alignItems="flex-end"
            spacing={isDesktop ? 3 : 1.5}
            direction="row"
          >
            {icons.map((item) => (
              <IconButton
                key={item.icon}
                href={item.link}
                target={item?.target}
              >
                <Iconify icon={item.icon} width={isDesktop ? 22 : 20} />
              </IconButton>
            ))}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
