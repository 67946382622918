import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
//slices
import notionReducer from "./slices/notion";

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});

const storage = createNoopStorage();

const rootPersistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  notion: notionReducer,
});

export { rootPersistConfig, rootReducer };
