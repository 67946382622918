import { Stack } from "@mui/material";
import styles from "./Button.module.css";
import { useLocation } from "react-router-dom";

export default function HomeButton() {
  const { pathname } = useLocation();
  const language = pathname.split("/").at(-1);
  return (
    <>
      <Stack>
        {" "}
        <a
          href={language === "en" ? "/en" : language === "lab" ? "/lab" : "/"}
          className={styles.HomeButton}
        >
          Home
        </a>
      </Stack>
    </>
  );
}
