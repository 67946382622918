const FONT_PRIMARY = "Noto Sans KR, sans-serif"; // Google Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: 100,
    letterSpacing: 2,
  },
  body1: {
    lineHeight: 2,
    fontSize: 18,
  },
};

export default typography;
