// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Container,
  Toolbar,
  Box,
  Stack,
  IconButton,
  List,
  Collapse,
} from "@mui/material";
import { bgBlur } from "../utils/cssStyles";
import useOffSetTop from "../hooks/useOffSetTop";
import useResponsive from "../hooks/useResponsive";
import HomeButton from "../components/HomeButton";
import HeaderList from "../components/HeaderList";
import useData from "../hooks/useData";
import { useEffect, useState } from "react";
import Iconify from "../components/Iconify";

export default function Header({ onOpenNav, openNav }) {
  const theme = useTheme();
  const isOffset = useOffSetTop(64);
  const isDesktop = useResponsive("up", "lg");

  const { header } = useData();
  const [update, setUpdate] = useState(false);

  const OpenMore = styled((props) => {
    const { openNav, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme }) => ({
    // transform: !openNav ? "rotate(0deg)" : "rotate(180deg)",
    marginRight: "0",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  function isEmptyObj(obj, setObj) {
    if (obj === null) {
      setObj(false);
    } else if (obj.constructor === Object && Object.keys(obj).length === 0) {
      setObj(false);
    } else {
      setObj(true);
    }
  }

  useEffect(() => {
    isEmptyObj(header, setUpdate);
  }, [header]);

  const cardMinContent = (
    <Stack
      direction="column"
      spacing={3}
      sx={{ ml: isDesktop ? 10 : 4, mt: 2, mb: 2 }}
    >
      {update && <HeaderList header={header} onOpenNav={onOpenNav} />}
    </Stack>
  );

  const renderContent = (
    <>
      {!isDesktop && (
        <Container
        // sx={{ display: "flex", justifyContent: "flex-end", width: "100vh" }}
        >
          <List>
            <List
              disablePadding
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <HomeButton />
              <OpenMore openNav={openNav} onClick={onOpenNav}>
                <Iconify icon="ic:outline-menu" />
              </OpenMore>
            </List>

            <Collapse in={openNav} timeout="auto" unmountOnExit>
              <List>{cardMinContent}</List>
            </Collapse>
          </List>
        </Container>
      )}
      {isDesktop && (
        <Container sx={{ display: "flex", alignItems: "center" }}>
          <HomeButton />
          {/* <Typography variant="h6">HOME</Typography> */}
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={5}>
            {update && <HeaderList header={header} onOpenNav={onOpenNav} />}
          </Stack>
        </Container>
      )}
    </>
  );

  return (
    <AppBar color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        disableGutters
        sx={{
          transition: theme.transitions.create(["height", "background-color"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...bgBlur({ color: theme.palette.background.default }),
          // height: {
          //   // md: 100,
          //   // xs: 100,
          // },
        }}
      >
        {renderContent}
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: "auto",
        borderRadius: "50%",
        position: "absolute",
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
