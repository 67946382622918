import { useContext } from "react";
import { SettingsContext } from "../settings/SettingsContext";

export default function useData() {
  const { header, onChangeHeader, doneHTML, onChangeDoneHTML } =
    useContext(SettingsContext);
  return {
    header,
    onChangeHeader,
    doneHTML,
    onChangeDoneHTML,
  };
}
