import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import ToggleButton from "../components/ToggleButton";
import { useState } from "react";
import useResponsive from "../hooks/useResponsive";

export default function MainLayout() {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive("up", "lg");

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1 }}>
      <Header onOpenNav={handleOpen} openNav={open} />
      <Outlet />

      {pathname !== "/lab" && <Footer />}
      {isDesktop && <ToggleButton />}
    </Box>
  );
}
