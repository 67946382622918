// @mui
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 999,
  width: "100vw",
  height: "100vh",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));
// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return <RootStyle></RootStyle>;
}
