import { Stack } from "@mui/material";
import styles from "./Button.module.css";

const { forwardRef } = require("react");

const RefButton = forwardRef(({ href, text, onClick }, ref) => (
  <>
    <Stack>
      <a
        // activeClass="active"
        ref={ref}
        href={href}
        onClick={onClick}
        className={styles.RefButton}
      >
        {text}
      </a>
    </Stack>
  </>
));

RefButton.displayName = "RefButton";

export default RefButton;
