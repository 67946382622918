import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
//
import { dispatch } from "../store";

const initialState = {
  doneLoading: false,
  error: null,
  datas: {},
  // doneInserted: false,
};

const slice = createSlice({
  name: "notion",
  initialState,
  reducers: {
    startLoading(state) {
      state.doneLoading = false;
    },

    hasError(state, action) {
      state.doneLoading = false;
      state.error = action.payload;
    },
    getDatasSuccess(state, action) {
      state.doneLoading = true;
      state.datas = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getNotions(url) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        // `https://notion-api.splitbee.io/v1/page/Company-b07546277ddb49ea9663a074e4e2d634`
        `https://notion-api.splitbee.io/v1/page/${url}`
      );
      dispatch(slice.actions.getDatasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
