import { Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./layouts/MainLayout";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const Main = Loadable(lazy(() => import("./pages/Main")));
export const PrivacyPolicy = Loadable(
  lazy(() => import("./pages/PrivacyPolicy"))
);
export const TermsOfServices = Loadable(
  lazy(() => import("./pages/TermsOfServices"))
);
export const ENmain = Loadable(lazy(() => import("./pages/en/ENmain")));
export const ENprivacyPolicy = Loadable(
  lazy(() => import("./pages/en/ENprivacyPolicy"))
);
export const ENtermsOfServices = Loadable(
  lazy(() => import("./pages/en/ENtermsOfServices"))
);

export const Lab = Loadable(lazy(() => import("./pages/Lab")));

// export const Ads = Loadable(lazy(() => import("./pages/Ads")));
// export const AppAds = Loadable(lazy(() => import("./pages/AppAds")));

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,

      children: [
        {
          path: "",
          children: [
            { path: "", element: <Main /> },
            { path: "en", element: <ENmain /> },
          ],
        },
        {
          path: "privacy-policy",
          children: [
            { path: "", element: <PrivacyPolicy /> },
            {
              path: "en",
              element: <ENprivacyPolicy />,
            },
          ],
        },
        {
          path: "terms-of-services",
          children: [
            { path: "", element: <TermsOfServices /> },
            {
              path: "en",
              element: <ENtermsOfServices />,
            },
          ],
        },
        { path: "lab", element: <Lab /> },
      ],
    },
    // {
    //   path: "/ads.txt",
    //   element: <Ads />,
    // },
    // {
    //   path: "/app-ads.txt",
    //   element: <AppAds />,
    // },
  ]);
}
