// theme
import ThemeProvider from "./theme";
import Router from "./routes";
import "./theme/index.css";

function App() {
  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}

export default App;
