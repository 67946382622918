import {
  useState,
  createContext,
  useMemo,
  useCallback,
  useContext,
} from "react";

// ----------------------------------------------------------------------

const initialState = {
  onChangeHeader: () => {},
};
// ----------------------------------------------------------------------

export const SettingsContext = createContext(initialState);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context)
    throw new Error("useSettingsContext must be use inside SettingsProvider");

  return context;
};

export function SettingsProvider({ children }) {
  const [header, setHeader] = useState(null);
  const [doneHTML, setDoneHTML] = useState(false);

  const onChangeHeader = useCallback((data) => {
    setHeader(data);
  }, []);
  const onChangeDoneHTML = useCallback((data) => {
    setDoneHTML(true);
  }, []);

  const value = useMemo(
    () => ({
      header,
      onChangeHeader,
      doneHTML,
      onChangeDoneHTML,
    }),
    [header, onChangeHeader, doneHTML, onChangeDoneHTML]
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}
