import { Stack } from "@mui/material";
import styles from "./Button.module.css";
import { useLocation } from "react-router-dom";

export default function LanguageButton() {
  const { pathname } = useLocation();
  const language = pathname.split("/").at(-1);
  const info = pathname.split("/")[1];
  if (pathname === "/" || pathname === "/en" || pathname === "/en/") {
    return (
      <>
        {info === "en" ? (
          <Stack>
            <a href={"../"} className={styles.RefButton}>
              KOR
            </a>
          </Stack>
        ) : (
          <Stack>
            <a href={`${language}/en`} className={styles.RefButton}>
              ENG
            </a>
          </Stack>
        )}
      </>
    );
  } else {
    return (
      <>
        {language === "en" ? (
          <Stack>
            <a href={`/${info}`} className={styles.RefButton}>
              KOR
            </a>
          </Stack>
        ) : (
          <Stack>
            <a href={`${language}/en`} className={styles.RefButton}>
              ENG
            </a>
          </Stack>
        )}
      </>
    );
  }
}
