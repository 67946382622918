import { createRef } from "react";
import LanguageButton from "./LanguageButton";
import RefButton from "./RefButton";
import { useLocation } from "react-router-dom";

export default function HeaderList({ header, onOpenNav }) {
  const { pathname } = useLocation();

  const info = pathname.split("/")[1];
  const language = pathname.split("/").at(-1);
  if (
    pathname === "/" ||
    pathname === "/en" ||
    pathname === "/en/" ||
    pathname === "/lab"
  ) {
    return (
      <>
        {Object.keys(header)
          .filter((item) => item !== "Profile")
          .map((item) => (
            <RefButton
              key={item}
              href={info === "lab" ? `#new-${item}` : `#${item}`}
              text={item}
              ref={createRef()}
              onClick={onOpenNav}
            />
          ))}
        {info !== "lab" && <LanguageButton />}
      </>
    );
  } else if (info === language) {
    // KOR PRIVACY, TERMS
    return (
      <>
        {Object.keys(header)
          .filter((item) => item !== "Profile")
          .map((item) => (
            <RefButton
              key={item}
              href={`../#${item}`}
              text={item}
              ref={createRef()}
              onClick={onOpenNav}
            />
          ))}
        <LanguageButton />
      </>
    );
  } else {
    //ENG PRIVACT, TERMS
    return (
      <>
        {Object.keys(header)
          .filter((item) => item !== "Profile")
          .map((item) => (
            <RefButton
              key={item}
              href={`../${language}/#${item}`}
              text={item}
              ref={createRef()}
              onClick={onOpenNav}
            />
          ))}
        <LanguageButton />
      </>
    );
  }
}
