import { Box, Tooltip } from "@mui/material";
// @mui
import { alpha, useTheme } from "@mui/material/styles";
import { bgBlur } from "../utils/cssStyles";
import Iconify from "./Iconify";
import IconButtonAnimate from "./IconButtonAnimate";
import useResponsive from "../hooks/useResponsive";

export default function ToggleButton() {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box
      sx={{
        p: 0.5,
        right: 24,
        bottom: isDesktop ? 24 : 120,
        zIndex: 999,
        position: "fixed",
        borderRadius: "50%",
        boxShadow: `-12px 12px 32px -4px ${alpha(
          theme.palette.grey[600],
          0.36
        )}`,
        ...bgBlur({ color: theme.palette.background.default }),
      }}
    >
      <Tooltip title="Top">
        <IconButtonAnimate
          color="primary"
          onClick={ScrollToTop}
          sx={{ p: 1.25 }}
        >
          <Iconify icon="mdi:arrow-top-drop-circle-outline" />
        </IconButtonAnimate>
      </Tooltip>
    </Box>
  );
}
